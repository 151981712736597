
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import { CONTENT_TYPE, ContentAssignment, ContentItem } from "@/types";
import ContentViewer from '@/components/ContentViewer.vue'
import { User } from "@/store/user/types";
import { USER_GETTERS } from "@/store/user/getters";
import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component({
  components: {
    ContentViewer
  }
})
export default class ViewContent extends Vue {
  assignment: ContentAssignment | null = null;
  item: ContentItem | null = null;

  videoDurationSeconds = 0;

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [])
    const assignment = await getDoc(doc(firestore, 'content-assignments', this.$route.params.id.toString()));
    if (!assignment.exists()) {
      this.$router.push('/404');
      return;
    }

    const item = await getDoc(doc(firestore, 'content-items', assignment.data().contentId));

    this.assignment = {
      ...assignment.data() as ContentAssignment,
      id: assignment.id,
    }

    this.item = {
      ...item.data() as ContentItem,
      id: item.id,
    }
  }

  setVideoDuration = (duration: number) => {
    this.videoDurationSeconds = duration;
  }

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async complete() {
    await updateDoc(doc(firestore, 'content-assignments', this.$route.params.id.toString()), {
      completed: true,
    })

    if (!this.item) return;

    if (this.item.type === CONTENT_TYPE.VIDEO) {
      const programs = await getDocs(query(collection(firestore, 'programs'), where('participant', '==', this.user.id)))
      if (programs.docs.length === 0) return;
      let program = programs.docs[0].id as string;


      const currentDate = new Date();
      const currentPhasesQuery = query(
        collection(firestore, 'phases'),
        where('start', '<=', currentDate),
        where('end', '>=', currentDate),
        where('programId', '==', program)
      );

      const currentPhases = await getDocs(currentPhasesQuery);
      if (currentPhases.docs.length === 0) return;
      const activities = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', currentPhases.docs[0].id)))

      for (let activity of activities.docs) {
        if (activity.data().toLowerCase().includes('screencast')) {
          await updateDoc(doc(firestore, 'content-assignments', this.$route.params.id.toString()), {
            hoursAdded: true,
          })
          await addDoc(collection(firestore, 'actual-activities'), {
            activityId: activity.id,
            actualHours: 2,
            comment: this.item.title,
            date: new Date(),
            participantPresent: true,
            participantConfirmation: null,
          })
          return;
        }
      }

    }

    await updateDoc(doc(firestore, 'content-assignments', this.$route.params.id.toString()), {
      hoursAdded: false,
    })

  }
}
